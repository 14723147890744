import { useEffect, useState } from "react";
import Header from "./header"
import "../assets/css/pagination.css";
import apiRequest from "../services/ServiceRequest";
import * as Url from '../../src/services/ServiceConstant';
import { toast } from 'react-toastify';
import Sidebar from "./menu"
import { Modal } from 'react-bootstrap';
import staff1 from '../assets/img/staff1.jpg';
import Select from "react-select";
import Axios from "axios";
import fileDownload from 'js-file-download';
import { validateName, validateEmail, validateMobileNo } from '../helpers/validation';
import { filterLocations, LoaderGif, sortLocationManagers, handleLocationChange } from "../utils/common.js"
import LocationManagerList from "./list/locationmanager";
import * as EmailTemplates from '../utils/emailtemplates.js';
import { dynamicStylesheet, removeUserSession, getToken } from '../utils/common';
function LocationManager() {
    var endpoints = ""
    toast.configure()
    const [locationvalue, setLocationValue] = useState([]);
    const [view_image_error, image_error] = useState("");
    const [viewImageError, setImageError] = useState(false);
    const [view_profileimage_error, profileimage_error] = useState("");
    const [viewProfileImageError, setProfileImageError] = useState(false);
    const [IdFileName, setIdFileName] = useState("");
    const [selectedLocations, setSelectedLocations] = useState("");
    var profiles_image = []
    var method = ''
    var IDS = []
    var update = ''
    const [get_data, setStaff] = useState([]);
    const [view_first_name_error, first_name_error] = useState("");
    const [view_Last_name_error, Last_name_error] = useState("");
    const [view_Email_error, Email_error] = useState("");
    const [view_Mobile_error, Mobile_error] = useState("");
    const [view_location_error, location_error] = useState("");
    const [view_Role_error, Role_error] = useState("");
    const [FilterEmail, setFilterEmail] = useState("");
    const [viewFirstNameError, setFirstNameError] = useState(false);
    const [viewLastNameError, setLastNameError] = useState(false);
    const [viewEmailError, setEmailError] = useState(false);
    const [viewMobileError, setMobileError] = useState(false);
    const [viewLocationError, setLocationError] = useState(false);
    const [show, setShow] = useState(false);
    const [labels, labelText] = useState("");
    const [showID, setShowID] = useState(false);
    const [ID, setID] = useState("");
    const [edits, setEdit] = useState(false);
    const [assigned_location, setassigned_location] = useState("");
    const [profile_image, setprofile_image] = useState("");
    const [assigned_role, setassigned_role] = useState("");
    const [email, setemail] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [address, setAddress] = useState("");
    const [first_name, setfirst_name] = useState("");
    const [last_name, setlast_name] = useState("");
    const [phone_number, setphone_number] = useState("");
    const [id, setId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [submit, setSubmit] = useState(false);
    const [view_error, viewError] = useState("");
    const [child_data, setChildData] = useState("0");
    const [loading, setLoading] = useState(true);
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const changeBlockStatus = async (id, block_status) => {
        if (window.confirm('Are you sure?')) {
            var response = await apiRequest({
                id: id,
                block_status: block_status
            }, Url.BASE_URL + '/LocationManagers', 'patch');
            if (response.data.id) {
                if (response.data.block_status == "blocked") {
                    document.getElementById(id).text = "Unblock"
                }
                if (response.data.block_status == "unblocked") {
                    document.getElementById(id).text = "Block"
                }
                notify("Updated successfully");
                handleCallback(0);
            }
        } else {
            return false;
        }
    }
    const renderData = (data) => {
        return (
            data.length > 0 ? <LocationManagerList
                values={{
                    data: data,
                    handleShowID: handleShowID,
                    onEdit: onEdit,
                    changeBlockStatus: changeBlockStatus,
                    onDelete: onDelete,
                    view_show_msg: view_show_msg,
                    locations: locations
                }}
            /> : <center><b>No Records found</b></center>
        );
    };
    const handleClose = () => {
        setLocationValue("");
        setSubmit(false)
        setShow(false)
        viewError("")
        setID("");
        setfirst_name("");
        setlast_name("");
        setassigned_location("");
        setassigned_role("");
        setemail("");
        setphone_number("");
        setLocationId("")
        first_name_error("")
        Last_name_error("")
        location_error("")
        Role_error("")
        Email_error("")
        Mobile_error("")
    };
    const handleCloseID = () => setShowID(false);
    const handleShow = () => {
        setLocationValue("");
        setSubmit(false)
        viewError("")
        setprofile_image("");
        setShow(true)
        labelText(`Add ${localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'} Manager`);
        setEdit(false)
    };
    const handleShowID = (ID) => {
        setShowID(true)
        setID(ID)
    }
    const _onChangeText = (type, text) => {
        if (type === 'first_name') {
            if (text !== '') {
                setfirst_name(text)
                const _ValidateFirstName = validateName(text, 'first name');
                if (_ValidateFirstName['status']) {
                    first_name_error('')
                    setFirstNameError(true)
                }
                else {
                    first_name_error(_ValidateFirstName['error'])
                    setFirstNameError(false)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setfirst_name(text)
                first_name_error('Please enter  first name')
                setFirstNameError(false)
            }
        }
        if (type === 'last_name') {
            if (text !== '') {
                setlast_name(text)
                const _ValidateLastName = validateName(text, 'last name');
                if (_ValidateLastName['status']) {
                    Last_name_error('')
                    setLastNameError(true)
                }
                else {
                    Last_name_error(_ValidateLastName['error'])
                    setLastNameError(false)
                }
            }
            else
                Last_name_error('Please enter last name.')
            setLastNameError(false)
            setlast_name(text)
        }
        if (type === 'phone_number') {
            if (text < 0) {
                alert("Phone number should not be a negative value!!");
                setphone_number("");
                return false;
            }
            if (text.indexOf('e') > -1) {
                Mobile_error('Phone number should not contain alphabets!!');
                setMobileError(true)
            }
            if (text !== '') {
                setphone_number(text)
                const _ValidateMobileNo = validateMobileNo(text, 'phone number');
                if (_ValidateMobileNo['status']) {
                    Mobile_error('')
                    setMobileError(true)
                }
                else {
                    Mobile_error(_ValidateMobileNo['error'])
                    setMobileError(false)
                }
            }
            else
                Mobile_error('Please enter valid mobile number.')
            setMobileError(false)
            setphone_number(text)
        }
        if (type === 'email') {
            if (text !== '') {
                setemail(text)
                const _ValidateEmail = validateEmail(text, 'email');
                if (_ValidateEmail['status']) {
                    Email_error('')
                    setEmailError(true)
                }
                else {
                    Email_error(_ValidateEmail['error'])
                    setEmailError(false)
                }
            }
            else
                Email_error('Please enter valid email.')
            setEmailError(false)
            setemail(text)
        }
        if (type === 'location_address') {
            if (text !== '') {
                setLocationId(text)
                location_error('')
                setLocationError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                setLocationId(text)
                location_error('Please enter  location Address')
                setLocationError(false)
            }
        }
    }
    const _onChangeID = async (images) => {
        setIdFileName(images.name);
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
        }
        else {
            if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
                image_error("Please upload the following file types: jpeg, and png.");
                setIdFileName("");
                return false;
            }
            var _size = images.size;
            if (_size > 1000000) {
                image_error("The size limit for images is 1MB. Reduce the file size and try again.");
                setIdFileName("");
                return false;
            }
            image_error('')
            setImageError(true)
            let formData = new FormData();
            formData.append('ID', images);
            let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setID(Url.image_url + image_response.data.ID)
        }
    }
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            profileimage_error('Please select image ')
            setProfileImageError(false)
        }
        else {
            if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
                profileimage_error("Please upload the following file types: jpeg, and png.");
                setIdFileName("");
                return false;
            }
            var _size = images.size;
            if (_size > 1000000) {
                profileimage_error("The size limit for images is 1MB. Reduce the file size and try again.");
                return false;
            }
            profileimage_error('')
            setProfileImageError(true)
            let formData = new FormData();
            formData.append('profile_image', images);
            let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setprofile_image(Url.image_url + image_response.data.profile_image)
        }
    }
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        // getLocationManagersdata()
        // var get_locations = await apiRequest([], Url.ADDLOCATION+'?filter={"where":{"id_deleted":'+false+'}}', 'get')
        // if (get_locations == null || get_locations == undefined || get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations === null || get_locations === undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
        // }
        // else {
        //     let temp_Array = get_locations.data;
        //     setLocationData(temp_Array)
        // }
    }, []);
    const getLocationManagersdata = async () => {
        let endpoint = Url.LOCATION_MANAGER + '?filter={"order":"first_name ASC","where":{"id_deleted":' + false + '},"include":["location"]}'
        var get_staff = await apiRequest([], endpoint, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.data === '{}' || Object.entries(get_staff.data).length === 0 || get_staff === null || get_staff === undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            setStaff(get_staff.data)
            setLoading(false);
        }
    }
    const onEdit = (edit) => {
        setEdit(true)
        setShow(true)
        labelText("Edit Location Manager")
        setId(edit.id)
        if (edit.locs != "") {
            var locationArr = [];
            for (var i = 0; i < edit.locs.length; i++) {
                var locationObj = {
                    value: edit.locations_assigned[i],
                    label: edit.locs[i]
                }
                locationArr.push(locationObj);
            }
            setLocationValue(locationArr);
            setLocationId(edit.locations_assigned);
            setImageError(false)
        }
        if (edit['profile_image'] != "") {
            setprofile_image(edit['profile_image'])
        }
        if (edit.ID != "") {
            setID(edit['ID'])
        }
        if (edit.email != "") {
            setemail(edit.email)
            setFilterEmail(edit.email);
        }
        if (edit['first_name'] != "") {
            setfirst_name(edit['first_name'])
        }
        if (edit['last_name'] != "") {
            setlast_name(edit['last_name'])
        }
        if (edit['mobile'] != "") {
            setphone_number(edit['mobile'])
        }
    };
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            let endpoint = Url.BASE_URL + `/LocationManagers`
            var delete_locationmanager = await apiRequest({
                id: id,
                id_deleted: true
            }, endpoint, 'patch')
            if (delete_locationmanager == null || delete_locationmanager == undefined || delete_locationmanager.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully")
                handleCallback(0);
            }
        } else {
            return false;
        }
    }
    function validMail(mail) {
        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
    }
    const onSubmit = async () => {
        let location_manager_url = Url.BASE_URL + '/insert-location-manager';
        setSubmit(true)
        if (first_name == '' || last_name == '' || email == '' || phone_number == '' || ID == '' || ID == null || ID == undefined || profile_image == '' || profile_image == null || profile_image == undefined) {
            {
                if (ID == null || ID == undefined || ID == [] || ID == '' || ID == {}) {
                    image_error('Please select image ')
                    setImageError(false)
                }
                if (locationvalue == null || locationvalue == undefined || locationvalue == [] || locationvalue == '' || locationvalue == {}) {
                    location_error('Please select location')
                    setLocationError(false)
                }
                if (profile_image == null || profile_image == undefined || profile_image == [] || profile_image == '' || profile_image == {}) {
                    profileimage_error('Please select image ')
                    setProfileImageError(false)
                }
                setfirst_name(first_name)
                setLocationId(locationId);
                const _ValidateFirstName = validateName(first_name, 'first name');
                if (_ValidateFirstName['status']) {
                    first_name_error('')
                    setFirstNameError(true)
                }
                else {
                    first_name_error(_ValidateFirstName['error'])
                    setFirstNameError(false)
                }
                setlast_name(last_name)
                const _ValidateLastName = validateName(last_name, 'last name');
                if (_ValidateLastName['status']) {
                    Last_name_error('')
                    setLastNameError(true)
                }
                else {
                    Last_name_error(_ValidateLastName['error'])
                    setLastNameError(false)
                }
                setphone_number(phone_number)
                const _ValidateMobileNo = validateMobileNo(phone_number, 'mobile number');
                if (_ValidateMobileNo['status']) {
                    Mobile_error('')
                    setMobileError(true)
                }
                else {
                    Mobile_error(_ValidateMobileNo['error'])
                    setMobileError(false)
                }
                setemail(email)
                const _ValidateEmail = validateEmail(email, 'email');
                if (_ValidateEmail['status']) {
                    Email_error('')
                    setEmailError(true)
                }
                else {
                    Email_error(_ValidateEmail['error'])
                    setEmailError(false)
                }
            }
        }
        else {
            var emailValidate = validMail(email);
            if (emailValidate == false) {
                Email_error('Invalid Email, Please enter valid mail!!');
                setEmailError(false);
                return false;
            }
            const _ValidateEmail = validateEmail(email, 'email');
            if (!_ValidateEmail['status']) {
                Email_error('Invalid Email, Please enter valid mail!!')
                setEmailError(false)
                return false;
            }
            if (phone_number.length != 10) {
                alert("Mobile number should be exactly 10 numeric digits!!");
                return false;
            }
            let formData = new FormData();
            var data =
            {
                "first_name": first_name,
                "last_name": last_name,
                "ID": "",
                "profile_image": "",
                "mobile": phone_number,
                "email": email,
                "locations_assigned": locationId,
                "locationId": "620ca111e96645b6a1c47015"
            }
            if (edits == false) {
                data.block_status = "unblocked";
            }
            var checkemail = await apiRequest({
                email: email,
                mobile: phone_number,
                id: id
            }, Url.BASE_URL + `/check-email-or-mobile-exists`, 'post');
            if (checkemail.data.email_or_mobile_exists == true) {
                alert("Email or mobile Already Exists in the System!! Please try another");
                return false;
            }
            try {
                if (profile_image.profile_image != null && profile_image.profile_image != undefined) {
                    formData.append('profile_image', profile_image.profile_image);
                    let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
                    if (image_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    }
                    data['profile_image'] = Url.image_url + image_response.data.profile_image
                }
                else {
                    data['profile_image'] = profile_image
                }
                if (ID.ID != null && ID.ID != undefined) {
                    formData.append('ID', ID.ID);
                    let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
                    if (image_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    }
                    data['ID'] = Url.image_url + image_response.data.data
                }
                else {
                    data['ID'] = ID
                }
                if (edits === false) {
                    method = 'post'
                    update = 'Added successfully'
                }
                else if (edits === true) {
                    method = 'patch'
                    data.id = id
                    update = 'Updated successfully'
                }
                handleCallback(0);
                data.id_deleted = false;
                if (edits === false) {
                    location_manager_url = Url.BASE_URL + '/insert-location-manager';
                } else {
                    location_manager_url = Url.BASE_URL + '/LocationManagers';
                }
                let staff_response = await apiRequest(data, location_manager_url, method)
                if (staff_response.error || staff_response == undefined || staff_response == null) {
                    errornotify("'Failed'");
                    return;
                } else {
                    let link = Math.floor((Math.random() * 100000000) + 1);
                    EmailTemplates.welcome_LocationManager({
                        location_manager_password_setip_link: `https://demodelivery.jumpgrowth.com/location-manager-setup-password?link=${link}&locationmanagerId=${staff_response?.data[0]?._id}`,
                        email: email,
                        name: first_name
                    });
                    setShow(false);
                    notify(update)
                    setID("");
                    setprofile_image("");
                    setfirst_name("");
                    setlast_name("");
                    setAddress("")
                    setemail("");
                    setphone_number("");
                    setSubmit(false)
                    setIdFileName("");
                    handleCallback(child_data)
                }
            }
            catch (err) {
                console.log(err);
                let url = Url.BASE_URL + `/LocationManagers/`
                let endpoints = url + '?filter={"where":{"email":"' + email + '"}}'
                var get_staff = await apiRequest([], endpoints, 'get')
                if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                    show_mesage("No records found")
                    setLoading(false);
                }
                else {
                    setEmailError(false)
                    Email_error('email already exists.')
                }
            }
        }
    }
    const searchLocationManager = (value) => {
        let loc_managers = get_data;
        const searchString = value.toLowerCase();
        if (searchString.length > 0) {
            const filteredResults = loc_managers.filter(item => {
                return (
                    item.first_name.toLowerCase().includes(searchString)
                )
            });
            if (filteredResults) {
                setStaff(filteredResults);
                setLoading(false);
                setcurrentPage(1);
            } else {
                handleCallback(0);
            }
        } else {
            handleCallback(0);
        }
    }
    const handleCallback = async (childData) => {
        setChildData(childData)
        if (childData == 0) {
            endpoints = Url.LOCATION_MANAGER + '?filter={"order":"first_name ASC","where":{"id_deleted":' + false + '},"include":["location"]}'
        }
        else if (childData != 0) {
            endpoints = Url.LOCATION_MANAGER + '?filter={"order":"first_name ASC","where":{"id_deleted":' + false + '},"include":["location"] }'
        }
        var get_staff = await apiRequest([], endpoints, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        var get_locations = await apiRequest([], Url.ADDLOCATION + '?filter={"where":{"id_deleted":' + false + '}}', 'get');
        if (get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations == null || get_locations == undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
        }
        else {
            let temp_Array = get_locations.data;
            // temp_Array.unshift({ id: 0, name: "Select Location", value: "" })
            temp_Array.unshift()
            let location = []
            location.push({
                label: 'All',
                value: 'All'
            });
            temp_Array.forEach(function (post, i) {
                let s = {
                    value: post.id,
                    label: post.name
                }
                location.push(s)
            });
            setLocationData(location)
            if (selectedLocations.length > 0) {
                let temp = []
                temp = selectedLocations
                let sp = []
                let locationId = []
                temp.forEach(function (post, i) {
                    let s = {
                        value: post.id,
                        label: ""
                    }
                    sp.push(s)
                })
                var tempFav = sp;
                var product = locations;
                product.forEach(productItem => {
                    tempFav.forEach(favItem => {
                        if (productItem.value === favItem.value) {
                            favItem.label = productItem.label;
                        }
                    });
                });
                setLocationValue(sp)
                setLocationId(locationId)
            }
        }
        var locations_of_manager = await filterLocations(childData, get_staff, get_locations);
        var staffData = await sortLocationManagers(locations_of_manager);
        setStaff(staffData);
        setLoading(false);
    }
    const [locations, setLocationData] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const downloadId = (file, fileName) => {
        Axios.get(file, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, fileName);
        });
    }
    return (
        <>
            <html lang="en">
                <link rel="stylesheet" href="css/custom.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar tabvalue='staff' />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">{
                                            localStorage.getItem("multi_restaurant") == "true" ? "Restaurant" : "Location"
                                        } Managers</h2>
                                        <div class="search_box_wrap search_customer">
                                            <div style={{ "display": "flex" }}>
                                                <div style={{ "display": "flex" }}>
                                                    <input type="text" onChange={(e) => searchLocationManager(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type to search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                </div>
                                                <button class="btn_sty_1" onClick={handleShow} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                </div>
                            </div>
                            <Modal class="modalform" className="globle-popup-width" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={show} onHide={handleClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{labels}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <section class="staff_profile_sec">
                                                <div class="row">
                                                    <div class="col-md-4"><div class="staff_profile_pic_box">
                                                        <div class="staff_profile_img">
                                                            {
                                                                (profile_image == '') ?
                                                                    <img src='./img-placeholder.png' alt="" /> : <img src={profile_image} alt="" />
                                                            }
                                                        </div>
                                                        <label class="custom-file-upload">
                                                            <input type="file" value={profiles_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                            Upload Image
                                                        </label>
                                                        {
                                                            (viewProfileImageError) ?
                                                                <small></small> : <><small style={{ color: 'red' }}>{view_profileimage_error}</small></>
                                                        }
                                                    </div></div>

                                                    <div class="col-md-8"><div class="staff_profile_form">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>First Name</label>
                                                                    <input type="text" class="form-control" value={first_name} onChange={e => _onChangeText('first_name', e.target.value)} />
                                                                    {
                                                                        viewFirstNameError ? <small></small> : <><small style={{ color: 'red' }}>{view_first_name_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>Last Name</label>
                                                                    <input type="text" class="form-control" value={last_name} onChange={e => _onChangeText('last_name', e.target.value)} />
                                                                    {
                                                                        viewLastNameError ? <small></small> : <><small style={{ color: 'red' }}>{view_Last_name_error}</small></>}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>Phone</label>
                                                                    <input type="number" class="form-control" value={phone_number} onChange={e => _onChangeText('phone_number', e.target.value)} />
                                                                    {
                                                                        viewMobileError ? <small></small> : <><small style={{ color: 'red' }}>{view_Mobile_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>Email</label>
                                                                    <input type="text" class="form-control" value={email} onChange={e => _onChangeText('email', e.target.value)} />
                                                                    {
                                                                        (viewEmailError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_Email_error}</small></>}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group" style={{ "z-index": "999" }}>
                                                                    <label>Assigned {localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}</label>
                                                                    <Select
                                                                        isMulti={true}
                                                                        value={locationvalue}
                                                                        onChange={(value) => handleLocationChange(value, locations,
                                                                            setLocationError,
                                                                            location_error,
                                                                            setLocationId,
                                                                            setLocationValue
                                                                        )}
                                                                        options={locations}
                                                                    />
                                                                    {
                                                                        (viewLocationError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_location_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>ID</label>
                                                                    <div class="custom-file">
                                                                        <input type="file" class="custom-file-input" id="customFile" value={IDS} onChange={e => _onChangeID(e.target.files[0])} />
                                                                        <label style={{ "width": "200px" }} class="custom-file-label" for="customFile"><p class="custom_label_file_name">{(IdFileName) ? (IdFileName) : 'Choose File'}</p></label>
                                                                    </div>
                                                                    {
                                                                        (viewImageError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_image_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>ID Image</label>
                                                                    <div>

                                                                        {
                                                                            (ID == '') ?
                                                                                <img style={{ "width": "100px !important", "height": "100px !important" }} src={ID} alt="" /> : <img src={ID} alt="" />
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></div>
                                                </div>
                                            </section>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer >
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                <button type="submit" class="btn_sty_1" style={{ align: 'left' }} onClick={onSubmit}> Submit</button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                            <Modal class="modal" size="m" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='false' show={showID} onHide={handleCloseID}>
                                <div>
                                    <div class="modal-content">
                                        <Modal.Header closeButton>
                                            <Modal.Title>ID Card</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <img style={{ 'margin-right': '10px' }} src={ID} alt="" />
                                            <br></br><br></br>
                                            <center><button onClick={() => downloadId(ID, 'location-manager-ID.' + ID.substring(ID.lastIndexOf("/") + 1))} class="btn btn-success">Download</button></center>
                                        </Modal.Body>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </>
    );
}
export default LocationManager;
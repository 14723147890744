import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import Select from 'react-select'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PromoCodesList from "./list/promocodes";
import "../assets/css/react_calendar.css";
import { setPromoCodeSetting, handleLocationChange, dynamicStylesheet } from '../utils/common.js';
import { PromocodesStatusFilter } from "./filters/promocodes";
const Promocode = (props) => {
    toast.configure()
    var update = ''
    var method = ''
    var profiles_image = []
    const [profile_image, setprofile_image] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [get_data, setPromoCode] = useState("");
    const [locations, setLocationData] = useState("");
    const [products, setProductData] = useState("");
    const [productId, setProductId] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [global_date, setGlobalDateFormat] = useState('');
    const [name, setName] = useState("");
    const [locationId, setLocationId] = useState([]);
    const [typevalue, setType] = useState("");
    const [values, setValue] = useState("Fixed");
    const [filterpromocodes, setFilterPromoCodes] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [min_value, setMinimunValue] = useState(0);
    const [discount_value, setDiscountValue] = useState("");
    const [validity, setValidity] = useState("date_range");
    const [date_title, setDateTitle] = useState("Date Range");
    const [value_title, setValueTitle] = useState("Fixed");
    const [highlightColor, setHighLightColor] = useState('');
    const [date_pick, setDatpick] = useState("date_range");
    const [type_pick, setTypepick] = useState("");
    const [labeltext, setlabeltext] = useState("");
    const [locationvalue, setLocationValue] = useState("");
    const [productvalue, setProductValue] = useState("");
    const [categoryvalue, setCategoryValue] = useState("");
    const [description, setDescription] = useState("");
    const [view_profileimage_error, profileimage_error] = useState("");
    const [viewProfileImageError, setProfileImageError] = useState(false);
    const [lifetime, setLifeTime] = useState("");
    const [dates, setdate] = useState(null);
    const [enddate, setEndDate] = useState(null);
    const [edits, setEdit] = useState(false);
    const [ID, setId] = useState("");
    const [label, labelText] = useState("");
    const [viewNameError, setNameError] = useState(false);
    const [viewTypeError, setTypeError] = useState(false);
    const [viewValueError, setValueError] = useState(false);
    const [viewValidityError, setValidityError] = useState(false);
    const [viewLocationError, setLocationError] = useState(false);
    const [viewAmountError, setAmountError] = useState(false);
    const [viewMimimumError, setMinimumError] = useState(false);
    const [viewSheduledError, setSheduledError] = useState(false);
    const [viewDescriptionError, setDescriptionError] = useState(false);
    const [viewVisibilityError, setVisibilityError] = useState(false);
    const [view_name_error, name_error] = useState("");
    const [view_type_error, type_error] = useState("");
    const [view_value_error, value_error] = useState("");
    const [view_validity_error, validity_error] = useState("");
    const [view_location_error, location_error] = useState("");
    const [view_amount_error, amount_error] = useState("");
    const [view_mimimum_error, minimum_error] = useState("");
    const [view_sheduled_error, sheduled_error] = useState("");
    const [view_description_error, description_error] = useState("");
    const [view_visibility_error, visibility_error] = useState("");
    const [enabless, setEnables] = useState(false);
    const [show, setShow] = useState(false);
    const [ModalWidth, setModalWidth] = useState("1000px");
    const [visibility, setVisibility] = useState('');
    const [loading, setLoading] = useState(true);
    const [validity_count, setMaxValidityCount] = useState('');
    const [categories, setCategories] = useState([]);
    const setValiditycount = (value) => {
        if (value < 0) {
            alert("Negative values are not allowed!!");
            setMaxValidityCount("");
        } else {
            setMaxValidityCount(value);
        }
    }
    const handleCheckbox = async (item, index) => {
        let temp = [...get_data]
        if (temp[index].enable === false || temp[index].enable === null || temp[index].enable === undefined || temp[index].enable == '') {
            let ss = true
            temp[index].enable = ss
            item.enable = ss
            let add_response = await apiRequest(item, Url.PROMOCODES, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setPromoCode(temp)
                setFilterPromoCodes(temp);
            }
        }
        else if (temp[index].enable === true || temp[index].enable === 'true') {
            let st = false
            item.enable = st
            temp[index].enable = st
            let add_response = await apiRequest(item, Url.PROMOCODES, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setEnables(true)
                setPromoCode(temp)
                setFilterPromoCodes(temp);
            }
        }
    }
    const handleCallback = async (childData) => {
        var widthofCurrentScreen = window.screen.availWidth;
        if (widthofCurrentScreen > 1000) {
            setModalWidth("1000px");
        }
        else {
            setModalWidth("500px");
        }
        let endpoints = ''
        if (childData == 0) {
            endpoints = Url.PROMOCODES + '?filter={"order":"id desc","where":{"id_deleted":' + false + '},"include":["location","product"]}'
            var get_staff = await apiRequest([], endpoints, 'get')
            if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                show_mesage("No records found")
                setPromoCode("")
                setFilterPromoCodes("");
                setLoading(false);
            }
            else {
                setPromoCode(get_staff.data)
                setFilterPromoCodes(get_staff.data);
                setLoading(false);
            }
        }
        else if (childData != 0) {
            endpoints = Url.PROMOCODES + '?filter={"order":"id desc","where":{"id_deleted":' + false + '},"include":["location","product"]}'
            var get_staff = await apiRequest([], endpoints, 'get')
            if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                show_mesage("No records found")
                setPromoCode("")
                setFilterPromoCodes("");
                setLoading(false);
            }
            else {
                let props = []
                get_staff.data.forEach(function (post, i) {
                    for (var i = 0; i < post.location.length; i++) {
                        if (post.location[i].id == childData) {
                            props.push(post)
                        }
                    }
                })
                setPromoCode(props)
                setFilterPromoCodes(props);
                setLoading(false);
            }
        }
    }
    const handleClose = () => {
        window.location.reload();
    }
    const handleShow = async () => {
        var categories = await apiRequest([], Url.BASE_URL + '/Categories?filter={"where":{"id_deleted":' + false + ',"enable":' + true + '}}', 'get');
        let temp_Category = categories.data;
        let category = []
        temp_Category.forEach(function (post, i) {
            let s = {
                value: post.id,
                label: post.name
            }
            category.push(s)
        });
        setCategories(category);
        setShow(true)
        setDiscountValue("")
        setprofile_image("");
        setMaxValidityCount("");
        setDatpick("date_range");
        setValue("Fixed")
        setValidity("date_range")
        labelText("Add Promo Code")
        setEdit(false)
    };
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const handleProductChange = (selectedOption) => {
        console.log(selectedOption);
        setProductValue(selectedOption);
        if (typevalue == "specific_product") {
            if (selectedOption.length <= 0) {
                setMinimumError(false)
                minimum_error('Please select Product')
            }
            else if (selectedOption.length > 0) {
                setMinimumError(true)
                minimum_error('')
                var price = []
                selectedOption.forEach(function (post, i) {
                    var p = post.value;
                    price.push(p)
                });
                setProductId(price)
            }
        }
    };
    const handleCategoryChange = (selectedOption) => {
        setCategoryValue(selectedOption);
        if (typevalue == "specific_category") {
            if (selectedOption.length <= 0) {
                setMinimumError(false)
                minimum_error('Please select Product')
            }
            else if (selectedOption.length > 0) {
                setMinimumError(true)
                minimum_error('')
                var price = []
                selectedOption.forEach(function (post, i) {
                    var p = post.value;
                    price.push(p)
                });
                setCategoryId(price)
            }
        }
    };
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            profileimage_error('Please select image ')
            setProfileImageError(false)
        }
        else {
            if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
                profileimage_error("Please upload the following file types: jpeg, and png.");
                return false;
            }
            var _size = images.size;
            if (_size > 1000000) {
                profileimage_error("The size limit for images is 1MB. Reduce the file size and try again.");
                return false;
            }
            profileimage_error('')
            setProfileImageError(true)
            let formData = new FormData();
            formData.append('profile_image', images);
            let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setprofile_image(Url.image_url + image_response.data.profile_image)
        }
    }
    const getpromocode = async () => {
        let endpoint = Url.PROMOCODES + '?filter={"order":"id desc","where":{"id_deleted":' + false + '},"include":["location","product"]}'
        var get_staff = await apiRequest([], endpoint, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.data === '{}' || Object.entries(get_staff.data).length === 0 || get_staff === null || get_staff === undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            setPromoCode(get_staff.data)
            setFilterPromoCodes(get_staff.data);
            setLoading(false);
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        var localSettings = await setPromoCodeSetting();
        await setGlobalDateFormat(localSettings?.[0]);
        var get_locations = await apiRequest([], Url.ADDLOCATION + '?filter={"where":{"id_deleted":' + false + '}}', 'get')
        if (get_locations == null || get_locations == undefined || get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations === null || get_locations === undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
        }
        else {
            let temp_Array = get_locations.data;
            let location = []
            location.push({
                label: 'All',
                value: 'All'
            });
            temp_Array.forEach(function (post, i) {
                let s = {
                    value: post.id,
                    label: post.name
                }
                location.push(s)
            });
            setLocationData(location)
        }
        var get_products = await apiRequest([], Url.PRODUCTS + '?filter={"where":{"id_deleted":' + false + '}}', 'get')
        if (get_products == null || get_products == undefined || get_products.data === '{}' || Object.entries(get_products.data).length === 0 || get_products === null || get_products === undefined || get_products.length <= 0 || get_products.data.length <= 0) {
        }
        else {
            let temp_Product = get_products.data;
            let product = []
            temp_Product.forEach(function (post, i) {
                let s = {
                    value: post.id,
                    label: post.name
                }
                product.push(s)
            });
            setProductData(product)
        }
    }, [global_date]);
    const renderData = (data) => {
        return (
            <PromoCodesList
                values={{
                    data: data,
                    handleCheckbox: handleCheckbox,
                    get_data: get_data,
                    global_date: global_date,
                    onEdit: onEdit,
                    onDelete: onDelete,
                    view_show_msg: view_show_msg
                }}
            />
        );
    };
    const onSubmit = async () => {
        if (viewNameError == false || viewAmountError == false || viewTypeError == false || viewLocationError == false || viewDescriptionError == false || profile_image == '' || profile_image == null || profile_image == undefined) {
            if (name == "") {
                name_error('Please enter name')
                setNameError(false)
            }
            if (profile_image == null || profile_image == undefined || profile_image == [] || profile_image == '' || profile_image == {}) {
                profileimage_error('Please select image ')
                setProfileImageError(false)
            }
            else {
                name_error('')
                setNameError(true)
            }
            if (description == "") {
                description_error('Please enter description')
                setDescriptionError(false)
            }
            else {
                description_error('')
                setDescriptionError(true)
            }
            if (visibility == "") {
                visibility_error('Please select visibility')
                setVisibilityError(false)
            }
            else {
                visibility_error('')
                setVisibilityError(true)
            }
            if (validity != "" && validity == "date_range") {
                if (dateRange[0] == null || dateRange[0] == undefined || dateRange[1] == null || dateRange[1] == undefined) {
                    sheduled_error('Please enter sheduled date')
                    setSheduledError(false)
                }
            }
            if (validity != "" && validity == "specific_date") {
                if (dates == null || dates == undefined) {
                    sheduled_error('Please enter sheduled date')
                    setSheduledError(false)
                }
            }
            if (validity != "" && validity == "lifetime") {
                if (lifetime != "lifetime" || lifetime == "") {
                    sheduled_error('Please enter sheduled date')
                    setSheduledError(false)
                }
            }
            if (values == "") {
                sheduled_error('Please select value')
            }
            if (values == "Fixed") {
                if (discount_value == null || discount_value == undefined || discount_value == '') {
                    setAmountError(false)
                    amount_error('Please enter amount')
                }
            }
            else if (values == "Percent") {
                if (discount_value == null || discount_value == undefined || discount_value == '') {
                    setAmountError(false)
                    amount_error('Please enter discount')
                }
            }
            if (typevalue == null || typevalue == undefined || typevalue == '') {
                type_error('Please select type')
                setTypeError(false)
            }
            if (typevalue == "minimum_order_value") {
                if (min_value == null || min_value == undefined || min_value == "") {
                    minimum_error('Please Enter minimum order value')
                    setMinimumError(false)
                }
            }
            else if (typevalue == "specific_product") {
                if (productId == null || productId == undefined || productId.length <= 0) {
                    setMinimumError(false)
                    minimum_error('Please select product')
                }
            }
            if (locationId == null || locationId == undefined || locationId.length <= 0) {
                setLocationError(false)
                location_error('Please select location')
            }
        }
        else {
            try {
                if (min_value != undefined || min_value != '') {
                    if (isNaN(min_value) == true || min_value?.includes('e') == true) {
                        alert("Invalid Minimum order value!!");
                        return false;
                    }
                }
            } catch (err) {
                console.log(err);
            }
            if (date_pick == "maximum_validity_count") {
                if (validity_count <= 0) {
                    alert("Invalid maximum validity count, it should be greater than or equal to 1!!");
                    return false;
                }
                if (validity_count.includes('e') == true) {
                    alert("Invalid maximum validity count, it should be greater than or equal to 1!!");
                    return false;
                }
            }
            if (discount_value <= 0) {
                alert("Invalid discount value, it should be greater than or equal to 1!!");
                return false;
            }
           
            if (lifetime == 'lifetime' && lifetime == '') {
                dateRange("")
                setdate("")
            }
            let type_values = ''
            if (typevalue == "minimum_order_value") {
                if (min_value <= 0) {
                    alert('Minimum order should be greater than 0!!');
                    return false;
                }
                type_values = "Minimum Order"
            }
            else if (typevalue == "specific_product") {
                type_values = "Specific Products"
            }
            else if (typevalue == "specific_discount") {
                type_values = "Direct Discount"
            } else {
                type_values = "Specific Categories"
            }
            let s = {
                "name": name,
                "amount": discount_value,
                "min_order_value": min_value,
                "value": values,
                "start_time": dateRange[0] ? dateRange[0] : dates,
                "end_time": dateRange[1] ? dateRange[1] : dates,
                "type": type_values,
                "validaty": validity,
                "description": description,
                "image": profile_image,
                "visibility": visibility
            }
            console.log(s);
            // return false;
            let product_promocode = {
                "promocodeId": ""
            }
            try {
                if (edits === false) {
                    method = 'post'
                    update = 'Added successfully'
                    s.enable = true
                    s.redeem_count = 0;
                    s.validity_count = validity_count;
                }
                else if (edits === true) {
                    method = 'patch'
                    s.id = ID
                    s.validity_count = validity_count;
                    update = 'Updated successfully'
                }
                let url = Url.BASE_URL + `/Promocodes`
                s.id_deleted = false;
                let staff_response = await apiRequest(s, url, method)
                product_promocode.promocodeId = staff_response.data.id
                if (staff_response.error) {
                    errornotify("Failed");
                    return;
                } else {
                    let throughmodel
                    if (typevalue == "specific_product") {
                        throughmodel = {
                            "promocodeId": staff_response.data.id,
                            "productId": productId,
                            "locationId": locationId
                        }
                    }
                    else {
                        throughmodel = {
                            "promocodeId": staff_response.data.id,
                            "locationId": locationId,
                            "productId": productId
                        }
                    }
                    console.log(productId);
                    let location_response = await apiRequest(throughmodel, Url.PROMOCODELOCATION, 'post')
                    console.log(location_response);
                    if (location_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    } else {
                        notify(update);
                        setShow(false)
                        setName("");
                        setMinimunValue("");
                        setType("");
                        setValue("")
                        setDescription("")
                        setValidity("")
                        setProductId("")
                        setCategoryId("");
                        setLocationId("")
                        setLifeTime("")
                        setdate("")
                        setProductValue("");
                        setLocationValue("")
                        setDateRange("")
                        name_error("")
                        validity_error("")
                        sheduled_error("")
                        value_error("")
                        amount_error("")
                        type_error('')
                        minimum_error("")
                        location_error("")
                        setMaxValidityCount('');
                        let endpoint = Url.PROMOCODES + '?filter={"order":"id desc","where":{"id_deleted":' + false + '},"include":["location","product"]}'
                        var get_category = await apiRequest([], endpoint, 'get')
                        setPromoCode(get_category.data)
                        setFilterPromoCodes(get_category.data);
                        setLoading(false);
                    }
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    }
    const _onChangeDropdown = (type, text) => {
        if (type === 'name') {
            setName(text)
            if (text !== '') {
                setName(text)
                name_error("")
                setNameError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                name_error('Please enter Name')
                setNameError(false)
            }
        }
        if (type === 'description') {
            setDescription(text)
            if (text !== '') {
                setDescription(text)
                description_error("")
                setDescriptionError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                description_error('Please enter description')
                setDescriptionError(false)
            }
            console.log(description);
        }
        if (type === 'visibility') {
            setVisibility(text)
            if (text !== '') {
                setVisibility(text)
                visibility_error("")
                setVisibilityError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                visibility_error('Please enter description')
                setVisibilityError(false)
            }
        }
        if (type == 'discount_value') {
            if (text < 0) {
                alert("Negative values are not allowed!!");
                setDiscountValue("");
                return false;
            }
            setDiscountValue(text)
            if (text !== '') {
                setDiscountValue(text)
                setAmountError(true)
                amount_error('')
            }
            else if (text === '' || text === undefined || text === null) {
                if (values == "Fixed") {
                    setAmountError(false)
                    amount_error('Please enter amount')
                }
                else if (values == "Percent") {
                    setAmountError(false)
                    amount_error('Please enter discount')
                }
            }
        }
        if (type === 'value') {
            if (text !== '') {
                setValue(text)
                setValueError(true)
                value_error('')
                if (text == 'Fixed') {
                    setValueTitle('Fixed')
                    setValue(text)
                }
                else if (text == 'Percent') {
                    setValueTitle('Percent')
                    setValue(text)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                value_error('Please select value')
                setValueError(false)
            }
        }
        if (type === 'types') {
            setType(text)
            if (text !== '') {
                setType(text)
                type_error('')
                setTypeError(true)
                if (text == 'specific_product') {
                    setTypepick("Products")
                    setlabeltext("Select Products")
                    minimum_error('')
                }
                if (text == 'specific_category') {
                    setTypepick("Categories")
                    setlabeltext("Select Categories")
                    minimum_error('')
                }
                else if (text == 'minimum_order_value') {
                    setTypepick("Order")
                    setlabeltext("Enter Minimum Order value")
                    minimum_error('')
                }
                else if (text == 'specific_discount') {
                    setTypepick("SpecificDiscount")
                    setlabeltext("")
                    minimum_error('')
                }
                else if (text == 'maximum_validity_count') {
                    setTypepick("Order")
                    setlabeltext("Enter Validity Count")
                    minimum_error('')
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setTypepick("")
                setlabeltext("")
                type_error('Please select type')
                setTypeError(false)
            }
        }
        if (type == 'typevalue') {
            setMinimunValue(text)
            if (text !== '') {
                minimum_error('')
                setMinimumError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                if (typevalue == "minimum_order_value") {
                    minimum_error('Please Enter minimum order value')
                    setMinimumError(false)
                }
                else if (typevalue == "specific_discount") {

                }
                else if (typevalue == "specific_product") {
                    if (productId.length <= 0) {
                        minimum_error('Please select product')
                        setMinimumError(false)
                    }
                }
                else if (typevalue == "specific_category") {
                    if (productId.length <= 0) {
                        minimum_error('Please select category')
                        setMinimumError(false)
                    }
                }
                else {
                    minimum_error('')
                    setMinimumError(true)
                }
            }
        }
        if (type === 'validity') {
            if (text !== '') {
                validity_error('')
                setValidityError(true)
                setValidity(text)
                setDatpick(text)
                if (text == 'specific_date') {
                    setDateTitle("Specific Date")
                    setLifeTime("")
                }
                else if (text == 'lifetime') {
                    setDateTitle("LifeTime")
                    setLifeTime("lifetime")
                }
                else if (text == 'date_range') {
                    setDateTitle("Date Range")
                    setLifeTime("")
                }
                else if (text == 'maximum_validity_count') {
                    setDateTitle("Maximum Validity Count")
                    setLifeTime("")
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setValidity("date_range")
                setDatpick("date_range")
                validity_error('Please enter Validity')
                setValidityError(false)
            }
        }
    }
    const onEdit = (edit) => {
        console.log(edit);
        labelText("Edit Promo Code")
        setEdit(true)
        if (edit.id != "") {
            setId(edit.id)
        }
        if (edit.visibility != "") {
            setVisibility(edit.visibility);
        }
        if (edit.validity_count != "") {
            setMaxValidityCount(edit.validity_count);
        }
        if (edit.name != "") {
            setName(edit.name)
            setNameError(true)
        }
        if (edit['image'] != "") {
            setprofile_image(edit['image'])
        }
        if (edit.description != "" && edit.description != null && edit.description != undefined) {
            setDescription(edit.description)
            setDescriptionError(true)
        }
        if (edit.validaty != null && edit.validaty != undefined && edit.validaty != "") {
            setValidity(edit.validaty)
            setDatpick(edit.validaty)
            if (edit.validaty == "date_range") {
                if (edit.start_time != "" && edit.end_time != "" && edit.start_time != null && edit.end_time != undefined && edit.start_time != null && edit.end_time != undefined) {
                    setDateTitle("Date Range")
                    setLifeTime("")
                    var sp = []
                    let startdate = new Date(edit.start_time);
                    let enddate = new Date(edit.end_time);
                    sp.push(startdate)
                    sp.push(enddate)
                    setDateRange(sp)
                    setSheduledError(true)
                    console.log(sp);
                }
            }
            else if (edit.validaty == "specific_date") {
                if (edit.start_time != "" && edit.end_time != "" && edit.start_time != null && edit.end_time != undefined && edit.start_time != null && edit.end_time != undefined) {
                    setDateTitle("Specific Date")
                    setLifeTime("")
                    let enddate = new Date(edit.end_time);
                    setdate(enddate)
                    setSheduledError(true)
                }
            }
            else if (edit.validaty == "lifetime") {
                setDateTitle("LifeTime")
                setLifeTime("lifetime")
                setSheduledError(true)
            }
            else if (edit.validaty == "maximum_validity_count") {
                setDateTitle("Maximum Validity Count")
                setSheduledError(true)
            }
        }
        if (edit.value != null && edit.value != undefined && edit.value != "") {
            setValue(edit.value)
            setValueTitle(edit.value)
            if (edit.amount != null && edit.amount != undefined && edit.amount != "") {
                if (edit.value == "Fixed") {
                    setValue(edit.value)
                    setDiscountValue(edit.amount)
                    setAmountError(true)
                }
                else if (edit.value == "Percent") {
                    setValue(edit.value)
                    setDiscountValue(edit.amount)
                    setAmountError(true)
                }
            }
        }
        if (edit.type != null && edit.type != undefined && edit.type != "") {
            setTypeError(true)
            if (edit.type == "Minimum Order") {
                setType("minimum_order_value")
                setTypepick("Order")
                setMinimunValue(edit.min_order_value)
                setlabeltext("Enter Minimum Order value")
                if (edit.min_order_value == "") {
                    setMinimumError(false)
                }
                else {
                    setMinimumError(true)
                }
            }
            else if (edit.type == "Specific Products") {
                setType("specific_product")
                setTypepick("Products")
                setlabeltext("Select Products")
                if (edit.product != "" && edit.product != null && edit.product != undefined && edit.product.length > 0) {
                    if (edit.product.length > 0) {
                        setMinimumError(true)
                        let temp = []
                        temp = edit.product
                        console.log(temp);
                        let sp = []
                        let productId = []
                        temp.forEach(function (post, i) {
                            let s = {
                                value: post.id,
                                label: ""
                            }
                            sp.push(s)
                            productId.push(post.id)
                        })
                        var tempFav = sp;
                        var product = products;
                        product.forEach(productItem => {
                            tempFav.forEach(favItem => {
                                if (productItem.value === favItem.value) {
                                    favItem.label = productItem.label;
                                }
                            });
                        });
                        setProductValue(sp)
                        setProductId(productId)
                    }
                    else {
                        setMinimumError(false)
                    }
                }
            }
            else if (edit.type == "Specific Categories") {
                setType("specific_category")
                setTypepick("Categories")
                setlabeltext("Select Categories")
                if (edit.category != "" && edit.category != null && edit.category != undefined && edit.category.length > 0) {
                    if (edit.category.length > 0) {
                        setMinimumError(true)
                        let temp = []
                        temp = edit.category
                        let sp = []
                        let categoryId = []
                        temp.forEach(function (post, i) {
                            let s = {
                                value: post.id,
                                label: ""
                            }
                            sp.push(s)
                            categoryId.push(post.id)
                        })
                        var tempFav = sp;
                        var category = categories;
                        category.forEach(productItem => {
                            tempFav.forEach(favItem => {
                                if (productItem.value === favItem.value) {
                                    favItem.label = productItem.label;
                                }
                            });
                        });
                        setCategoryValue(sp)
                        setCategoryId(productId)
                    }
                    else {
                        setMinimumError(false)
                    }
                }
            }
            else if (edit.type == "Direct Discount") {
                setType("specific_discount")
                setTypepick("SpecificDiscount")
                setMinimunValue(edit.min_order_value)
                setlabeltext("")
                if (edit.min_order_value == "") {
                    setMinimumError(false)
                }
                else {
                    setMinimumError(true)
                }
            }
        }
        if (edit.location != "" && edit.location != null && edit.location != undefined && edit.location.length > 0) {
            if (edit.location.length > 0) {
                setLocationError(true)
                let temp = []
                temp = edit.location
                let sp = []
                let locationId = []
                temp.forEach(function (post, i) {
                    if (post.id_deleted == false) {
                        let s = {
                            value: post.id,
                            label: ""
                        }
                        sp.push(s)
                        locationId.push(post.id);
                    }
                })
                var tempFav = sp;
                var product = locations;
                product.forEach(productItem => {
                    tempFav.forEach(favItem => {
                        if (productItem.value === favItem.value) {
                            favItem.label = productItem.label;
                        }
                    });
                });
                setLocationValue(sp)
                setLocationId(locationId)
            } else { setLocationError(false) }
        }
        setShow(true)
    }
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            let endpoint = Url.PROMOCODES;
            var delete_locationmanager = await apiRequest({
                id: id,
                id_deleted: true
            }, endpoint, 'patch')
            if (delete_locationmanager == null || delete_locationmanager == undefined || delete_locationmanager.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully")
                handleCallback(0);
            }
        } else {
            return false;
        }
    }
    const SearchData = (value) => {
        let data = get_data;
        const searchString = value.toLowerCase();
        if (searchString.length > 0) {
            const filteredResults = data.filter(item => {
                return (
                    item.name.toLowerCase().includes(searchString)
                )
            });
            if (filteredResults) {
                setPromoCode(filteredResults);
                setFilterPromoCodes(filteredResults);
                setcurrentPage(1);
            } else {
                getpromocode();
            }
        } else {
            getpromocode();
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const filterPromocodes = (filtertype) => {
        var promocodes = [];
        if (filtertype == "all") {
            window.location.reload();
        } else if (filtertype == "expired") {
            for (var i = 0; i < filterpromocodes.length; i++) {
                if (filterpromocodes[i].end_time < new Date().toISOString() && filterpromocodes[i].validaty != "lifetime" && filterpromocodes[i].validaty != "maximum_validity_count") {
                    promocodes.push(filterpromocodes[i]);
                }
            }
            setPromoCode(promocodes);
        } else if (filtertype == "not expired") {
            for (var i = 0; i < filterpromocodes.length; i++) {
                if (filterpromocodes[i].end_time > new Date().toISOString() || filterpromocodes[i].validaty == "lifetime" || filterpromocodes[i].validaty == "maximum_validity_count") {
                    promocodes.push(filterpromocodes[i]);
                }
            }
            setPromoCode(promocodes);
        }
    }
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar tabvalue='staff' />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_banners">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Promo Codes</h2>
                                        {PromocodesStatusFilter(filterPromocodes)}
                                        <div class="search_box_wrap search_customer">
                                            <div style={{ "display": "flex" }}>
                                                <div style={{ "display": "flex" }}>
                                                    <input autocomplete="off" type="text" onChange={(e) => SearchData(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type Name to Search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                </div>
                                                <button class="btn_sty_1" onClick={handleShow} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                </div>
                            </div>
                            <Modal class="modalform" className="globle-popup-width" size="lg" class="modal-dialog modal-dialog-centered modal-lg modal-md modal-sm" fullscreen='true' show={show} onHide={handleClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{label}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <section class="staff_profile_sec">
                                                <div class="row">
                                                    <div class="col-md-4"><div class="staff_profile_pic_box">
                                                        <div class="staff_profile_img">
                                                            {
                                                                (profile_image == '') ?
                                                                    <img src='./img-placeholder.png' alt="" /> : <img src={profile_image} alt="" />
                                                            }
                                                        </div>
                                                        <label class="custom-file-upload">
                                                            <input type="file" value={profiles_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                            Upload Image
                                                        </label>
                                                        {
                                                            (viewProfileImageError) ?
                                                                <small></small> : <><small style={{ color: 'red' }}>{view_profileimage_error}</small></>
                                                        }
                                                    </div></div>
                                                    <div class="col-md-8">
                                                        <div class="staff_profile_form">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Name</label>
                                                                        <input type="text" class="form-control" value={name} onChange={e => _onChangeDropdown('name', e.target.value)} />
                                                                        {(viewNameError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_name_error}</small></>}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group"  >
                                                                        <label>Validity</label>
                                                                        <div class="input-group" >
                                                                            <DropdownButton class="btn btn-outline range-bor dropdown-toggle" value={validity}
                                                                                onSelect={e => _onChangeDropdown('validity', e)} variant="Secondary" aria-haspopup="true" aria-expanded="false" id="dropdown-basic-button" class="input-group-prepend" title={date_title}>
                                                                                <Dropdown.Item href="#/action-1" eventKey="date_range">Date Range</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-1" eventKey="specific_date" >Specific Date</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-2" eventKey="lifetime" >Lifetime</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-2" eventKey="maximum_validity_count" >Maximum Validity Count</Dropdown.Item>
                                                                            </DropdownButton>
                                                                            {(viewValidityError) ?
                                                                                null : <><small style={{ color: 'red' }}>{view_validity_error}</small></>}
                                                                            {
                                                                                (date_pick == 'date_range') ?
                                                                                    <div class=
                                                                                        "myContainer datePickerPromoCode">
                                                                                        <DatePicker
                                                                                            onKeyDown={(e) => {
                                                                                                e.preventDefault();
                                                                                            }}
                                                                                            minDate={new Date()}
                                                                                            style={{ "width": "30% !important" }}
                                                                                            className="form-control"
                                                                                            selectsRange={true}
                                                                                            startDate={startDate}
                                                                                            endDate={endDate}
                                                                                            onChange={(update) => {
                                                                                                setDateRange(update);
                                                                                                setdate("")
                                                                                                if (validity != "" && validity == "date_range") {
                                                                                                    if (update[0] == null || update[0] == undefined || update[1] == null || update[1] == undefined) {
                                                                                                        sheduled_error('Please enter sheduled date')
                                                                                                        setSheduledError(false)
                                                                                                    }
                                                                                                    else {
                                                                                                        sheduled_error('')
                                                                                                        setSheduledError(true)
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    : (date_pick == 'lifetime') ? <input type="text" class="form-control" size="md" readOnly={true} placeholder="Life Time" /> :
                                                                                        (date_pick == 'specific_date') ?
                                                                                            <div class="myContainer">
                                                                                                <DatePicker
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.preventDefault();
                                                                                                    }}
                                                                                                    minDate={new Date()}
                                                                                                    style={{ "width": "30% !important" }}
                                                                                                    className="form-control"
                                                                                                    selected={dates}
                                                                                                    onChange={(update) => {
                                                                                                        setdate(update);
                                                                                                        setDateRange("");
                                                                                                        if (validity != "" && validity == "specific_date") {
                                                                                                            if (update == null || update == undefined) {
                                                                                                                sheduled_error('Please enter sheduled date')
                                                                                                                setSheduledError(false)
                                                                                                            }
                                                                                                            else {
                                                                                                                sheduled_error('')
                                                                                                                setSheduledError(true)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div> : (date_pick == 'maximum_validity_count') ? <input onChange={(e) => setValiditycount(e.target.value)} type="number" value={validity_count} class="form-control" size="md" readOnly={false} placeholder="Count" /> : ""
                                                                            }
                                                                            {(viewSheduledError) ?
                                                                                null : <><small style={{ color: 'red' }}>{view_sheduled_error}</small></>}
                                                                        </div>
                                                                    </div></div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Value</label>
                                                                        <div class="input-group">
                                                                            <DropdownButton class="btn btn-outline range-bor dropdown-toggle" value={values}
                                                                                onSelect={e => _onChangeDropdown('value', e)} aria-haspopup="true" aria-expanded="false" variant="Secondary" id="dropdown-basic-button" class="input-group-prepend" title={value_title}>
                                                                                <Dropdown.Item href="#/action-1" eventKey="Fixed" >Fixed</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-2" eventKey="Percent" selected="true">Percent</Dropdown.Item>
                                                                            </DropdownButton>
                                                                            {(viewValueError) ?
                                                                                null : <><small style={{ color: 'red' }}>{view_value_error}</small></>}
                                                                            <input type="text" class="form-control" onChange={e => _onChangeDropdown('discount_value', e.target.value)} value={discount_value} />
                                                                        </div>
                                                                        {(viewAmountError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_amount_error}</small></>}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Type</label>
                                                                        <select class="form-select form-control" value={typevalue} onChange={e => _onChangeDropdown('types', e.target.value)}>
                                                                            <option value="">Select Type</option>
                                                                            <option value="specific_product">Specific Products</option>
                                                                            <option value="specific_category">Specific Categories</option>
                                                                            <option value="minimum_order_value">Minimum Order</option>
                                                                            <option value="specific_discount">Direct Discount</option>
                                                                        </select>
                                                                        {(viewTypeError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_type_error}</small></>}
                                                                    </div>
                                                                </div>
                                                                {typevalue != "" ? <div class="col-md-12">
                                                                    <div style={{ "margin-top": "38px" }} class="form-group">
                                                                        <label>{labeltext}</label>
                                                                        {
                                                                            (type_pick == 'Products') ?
                                                                                <Select
                                                                                    style={{ "position": "unset" }}
                                                                                    isMulti={true}
                                                                                    value={productvalue}
                                                                                    onChange={(value) => handleProductChange(value)}
                                                                                    options={products}
                                                                                />
                                                                                : (type_pick == 'Order') ?
                                                                                    <input type="text" class="form-control" onChange={e => _onChangeDropdown('typevalue', e.target.value)} value={min_value} /> :
                                                                                    (type_pick == 'Categories') ?
                                                                                        <Select
                                                                                            style={{ "position": "unset" }}
                                                                                            isMulti={true}
                                                                                            value={categoryvalue}
                                                                                            onChange={(value) => handleCategoryChange(value)}
                                                                                            options={categories}
                                                                                        /> :
                                                                                        null
                                                                        }
                                                                        {(viewMimimumError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_mimimum_error}</small></>}
                                                                    </div>
                                                                </div> : ""}

                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>{localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}</label>
                                                                        <Select
                                                                            className="loc_tax"
                                                                            name="label"
                                                                            isMulti={true}
                                                                            value={locationvalue}
                                                                            onChange={(value) => handleLocationChange(value, locations,
                                                                                setLocationError,
                                                                                location_error,
                                                                                setLocationId,
                                                                                setLocationValue
                                                                            )}
                                                                            options={locations}
                                                                        />
                                                                        {(viewLocationError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_location_error}</small></>}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Promocode visibility</label>
                                                                        <select onChange={(e) =>
                                                                            _onChangeDropdown('visibility', e.target.value)
                                                                        } class="form-control" name="visibility">
                                                                            <option value="">Select Visibility</option>
                                                                            <option selected={visibility == "public" ? true : false} value="public">Public</option>
                                                                            <option selected={visibility == "private" ? true : false} value="private">Private</option>
                                                                        </select>
                                                                        {(viewVisibilityError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_visibility_error}</small></>}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Description</label>
                                                                        <textarea type="text" style={{ width: '100%', height: '100px' }} class="form-control" value={description} onChange={e => _onChangeDropdown('description', e.target.value)} />
                                                                        {(viewDescriptionError) ?
                                                                            null : <><small style={{ color: 'red' }}>{view_description_error}</small></>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div></div>
                                            </section>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                <button type="submit" class="btn_sty_1" style={{ "width": '110px;' }} onClick={() => onSubmit()} >Submit</button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default Promocode;